const formHelp = require("../../../helpers/FormHelpMsg");

export const validarCampoSenha = (senha) => {
  if (senha === "") {
    formHelp.showHelp(
      "senha",
      "Este campo deve ser preenchido.",
      "box-input-required"
    );
    return false;
  }

  if (senha.length < 6) {
    formHelp.showHelp(
      "senha",
      "Nome deve conter ao menos 6 caracteres.",
      "box-input-required"
    );
    return false;
  }

  // const strongRegex = new RegExp(
  //   "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])"
  // );

  // if (!strongRegex.test(senha)) {
  //   formHelp.showHelp('senha', 'A sua senha deve ter no mínimo 8 caracteres, sendo que deve conter pelo menos uma letra maiúscula, uma letra minúscula e um número.', 'box-input-required');
  //   return false;
  // }

  return true;
};

export const validarCampoConfirmaSenha = (senha, confirmaSenha) => {
  if (confirmaSenha === "") {
    formHelp.showHelp(
      "senha_confirma",
      "Este campo deve ser preenchido.",
      "box-input-required"
    );
    return false;
  }
  if (senha !== confirmaSenha) {
    formHelp.showHelp(
      "senha_confirma",
      "Senha e confirmação de senha devem ser iguais.",
      "box-input-required"
    );
    return false;
  }
  return true;
};
