import $ from "jquery";
import queryString from "query-string";
import React, { Component } from "react";

import * as MotoristaActions from "../../../actions/MotoristaActions";
import { decodeToken } from "./../../../helpers/JwtHelper";

export default class FormRecadastrar extends Component {
  constructor(props) {
    super();

    this.state = {
      email: "",
      senha: "",
      repetirSenha: "",
      token: "",
      show: false,
      error: false,
      labelBotao: "Cadastrar Senha",
      acaoBotaoIsLogin: false,
      showForm: true,
    };

    this.camposObrigatorios = ["repetirSenha", "senha"];
  }

  componentDidMount() {
    const values = queryString.parse(this.props.location.search);
    const dataToken = decodeToken(values.token);

    if (dataToken) {
      let data = this.state;

      data.token = values.token;
      data.email = dataToken.data.email;

      this.setState(data);
    } else {
      this.setState({
        error:
          "O e-mail para troca de senha que você recebeu está expirado, solicite novamente.",
        showForm: false,
      });
    }

    $(".box-input").on('click', () => {
      var label = $(this).find(".input-label");
      var input = $("#" + label.attr("for"));

      label.removeClass("input-label");
      label.addClass("input-label-sm");
      input.removeClass("input-hide");

      $(".box-input-sel").removeClass("box-input-sel");
      $(this).addClass("box-input-sel");

      input.trigger("click");
    });
  }

  _validarForm = () => {
    let formOk = true;

    this.setState({
      error: null,
    });

    $("#senha")
      .parent()
      .removeClass("box-input-required");

    $("#repetirSenha")
      .parent()
      .removeClass("box-input-required");

    this.camposObrigatorios.forEach((item) => {
      if ($("#" + item).val() === "") {
        $("#" + item)
          .parent()
          .addClass("box-input-required");
        formOk = false;
      }
    });

    if (this.state.senha !== this.state.repetirSenha) {
      $("#repetirSenha")
        .parent()
        .addClass("box-input-required");

      this.setState({
        error: "Senha e confirmação de senha devem ser iguais.",
      });

      formOk = false;
    }

    if (this.state.senha.length < 6) {
      $("#senha")
        .parent()
        .addClass("box-input-required");

      this.setState({
        error: 'A sua senha deve ter no mínimo 8 caracteres.',
      });

      formOk = false;
    }

    // if (this.state.senha !== "") {
    //   let strongRegex = new RegExp(
    //     "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])"
    //   );

    //   if (!strongRegex.test(this.state.senha) || this.state.senha.length < 8) {
    //     $("#senha")
    //       .parent()
    //       .addClass("box-input-required");

    //     this.setState({
    //       error:
    //         'A sua senha deve ter no mínimo 8 caracteres, sendo que deve conter pelo menos uma letra maiúscula, uma letra minúscula e um número.',
    //     });

    //     formOk = false;
    //   }
    // }

    return formOk;
  }

  _gravar = () => {
    if (this.state.acaoBotaoIsLogin) {
      window.location = "/login";
    } else {
      if (this._validarForm()) {
        $(".loader-main").removeClass("loader-inactive");

        let { email, senha, token } = this.state;

        MotoristaActions
          .recadastrarSenha(email, senha, token)
          .then((response) => {
            if (response.data.isOk) {
              this.setState({
                show: true,
                labelBotao: "FAÇA O LOGIN",
                acaoBotaoIsLogin: true,
                error: null,
              });
            } else {
              if (response.data.message) {
                this.setState({ error: response.data.message });
              } else {
                this.setState({
                  error:
                    "Não foi possivel alterar, solicite a senha novamente.",
                });
              }
            }

            $(".loader-main").addClass("loader-inactive");
          })
          .catch((error) => {
            this.setState({ error: error.message });
            $(".loader-main").addClass("loader-inactive");
          });
      }
    }
  };

  _updateField = async (event) => {
    let dados = this.state;
    dados[event.target.id] = event.target.value;
    await this.setState(dados);
    this._validarForm();
  };

  _esqueciMinhaSenha = () => {
    window.location = "/esqueci-senha";
  };

  render() {
    return (
      <div className="page-content header-clear">
        <div className="title title-36">NOVA SENHA</div>
        <div className="space-32"></div>
        <div
          className="space-71"
          style={ this.state.showForm ? { display: "none" } : {} }
        ></div>
        <div
          className="cf-texto div-body margin-30"
          style={ this.state.showForm ? {} : { display: "none" } }
        >
          Insira sua nova senha para <br />
          { this.state.email }
        </div>
        <div
          className="space-71"
          style={ this.state.show || this.state.error ? { display: "none" } : {} }
        ></div>
        <div
          className="space-20"
          style={ this.state.show || this.state.error ? {} : { display: "none" } }
        ></div>
        <div
          className="alert alert-success div-div-center margin-25"
          style={ this.state.show ? { padding: "6px" } : { display: "none" } }
        >
          Senha alterada com sucesso. Efetue o login novamente.
        </div>
        <div
          className="alert alert-danger margin-25"
          style={
            this.state.error
              ? { padding: 6, marginLeft: 10, marginRight: 10 }
              : { display: "none" }
          }
        >
          { this.state.error }
        </div>
        <div className="space-16"></div>
        <div
          className="box-input margin-25"
          style={ this.state.showForm ? {} : { display: "none" } }
        >
          <label className="input-label" htmlFor="senha">
            Senha
          </label>
          <input
            type="password"
            id="senha"
            className="input input-hide"
            value={ this.state.senha }
            onChange={(e) => {
              this._updateField(e);
            }}
          />
        </div>
        <div
          className="box-input margin-25"
          style={ this.state.showForm ? {} : { display: "none" } }
        >
          <label className="input-label" htmlFor="repetirSenha">
            Repetir Senha
          </label>
          <input
            type="password"
            id="repetirSenha"
            className="input input-hide"
            value={ this.state.repetirSenha }
            onChange={(e) => {
              this._updateField(e);
            }}
          />
        </div>
        <div className="space-26"></div>
        <div className="div-center">
          <button
            className="bt-login margin-30"
            onClick={ () => this._gravar() }
            style={ this.state.showForm ? {} : { display: "none" } }
          >
            { this.state.labelBotao }
          </button>
          <button
            className="bt-login margin-30"
            onClick={ () => this._esqueciMinhaSenha()}
            style={ this.state.showForm ? { display: "none" } : {} }
          >
            ESQUECI MINHA SENHA
          </button>
        </div>
        <div className="space-26"></div>
      </div>
    );
  }
}
